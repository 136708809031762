import * as React from 'react';
import chunk from 'lodash/chunk';
import get from 'lodash/get';

import { ChallengeCard } from './ChallengeCard';

import styles from './Widget.st.css';

import { VIEW_MODE } from '../../../config/constants';
import { EmptyList } from './EmptyList';

import { listenToHeightChanges } from 'wix-height-updater';
import { setWidthRestrictions } from '../../../services/resizeWindow';
import { withWixSDKWidget } from '../../../services/withWixSDK';
import { IWixSDKContext } from 'yoshi-flow-editor-runtime';
import { IChallengeListStateProps, IChallengeListState } from './interfaces';
import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withChallengesListData } from '../../../contexts/ChallengesListDataProvider/withChallengesListData';
import { withSettings } from '../../../contexts/Settings/withSettings';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withBI } from '../../../contexts/BI/withBI';
import { ButtonNames } from '../../../contexts/BI/interfaces';
import { Pages } from '../../../contexts/Location/LocationContext';
import { Spinner } from '../../../components-shared/Spinner';
import { isFullWidth } from '../../../selectors/platform';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';
import {
  ParticipantState,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';

export class ChallengeList extends React.Component<
  IChallengeListStateProps & IWixSDKContext,
  IChallengeListState
> {
  static displayName = 'ChallengeList';
  removeResizeListener: () => void;

  state = {
    isLoading: false,
    isEditorLoaded: false,
  };

  componentDidMount() {
    if (this.props.viewMode === VIEW_MODE.Editor) {
      listenToHeightChanges(this.props.Wix, window);
      this.removeResizeListener = setWidthRestrictions(this.props.Wix);
    }

    // @ts-ignore
    this.setState({ isEditorLoaded: this.props.editorLoaded });
  }

  toggleIsLoading = () => {
    this.setState({ isLoading: !this.state.isLoading });
  };

  render() {
    const {
      formFactor,
      challengesListData,
      settings,
      buttonState,
      goToPage,
      host,
      userPaidPlans,
      renderingEnv,
      memberWebAppButtonClick,
      ...rest
    } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className={styles.loader}>
          <Spinner />
        </div>
      );
    }

    if (!challengesListData || !challengesListData.totalCount) {
      return <EmptyList />;
    }
    const isMobile = formFactor === 'Mobile';
    const items = challengesListData.memberChallenges;
    const filteredChallenges = items.filter((challenge) => {
      const participantState = get(
        challenge.summary,
        'participation.state',
      ) as ParticipantState;
      const isFinished =
        get(challenge, 'challenge.transitions[0].state') ===
        V1ChallengeState.FINISHED;
      const isParticipant = userTypeHandlers.isJoinedAlready(participantState);

      return !isFinished || isParticipant;
    });
    const rows = chunk(filteredChallenges, settings.itemsPerRow);
    const rowsFilled = rows.map((el) =>
      el.length < settings.itemsPerRow
        ? new Array(settings.itemsPerRow)
            .fill(null)
            .map((_el2, idx) => (el[idx] ? el[idx] : null))
        : el,
    );

    return (
      <div
        data-hook="challenge-list-wrapper"
        {...styles('root', { mobile: isMobile }, rest)}
      >
        {rowsFilled.map((row, i) => (
          <ul
            key={`challenges-list-${i}`}
            data-hook="challenge-list"
            className={styles.wrapper}
            style={{
              gridGap: `${settings.cardSpacing}px`,
              marginTop:
                i > 0 && i < rowsFilled.length
                  ? `${settings.cardSpacing}px`
                  : 0,
              justifyItems:
                settings.itemsPerRow === 1 || isMobile ? `center` : null,
            }}
          >
            {row.map((item, index) => {
              return (
                <li className={styles.wrapperItem} key={index}>
                  {!!item && (
                    <ChallengeCard
                      memberChallenge={item}
                      isFullWidth={isFullWidth(host.dimensions)}
                      goToPage={() => {
                        // @ts-ignore
                        if (!this.state.isEditorLoaded) {
                          return;
                        }

                        void memberWebAppButtonClick({
                          buttonName: ButtonNames.ViewChallenge,
                          challengeId: item.challenge.id,
                        });

                        this.toggleIsLoading();
                        goToPage({
                          pageId: Pages.Details,
                          challengeId: item.challenge.id,
                        });
                      }}
                      userPaidPlans={userPaidPlans}
                      isMobile={isMobile}
                      buttonState={buttonState}
                      settings={settings}
                      renderingEnv={renderingEnv}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        ))}
      </div>
    );
  }
}

export default applyProviders(ChallengeList, [
  withProviders,
  withSettings,
  withLocation,
  withBI,
  withGeneralData,
  withChallengesListData,
  withPaidPlans,
  withWixSDKWidget,
]);
