import * as React from 'react';
import get from 'lodash/get';
import { translate } from 'react-i18next';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';

import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';

import {
  ListChallengesResponseChallengeSummary,
  ParticipantState,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';

import styles from './Badge.st.css';

export interface IBadgeProps {
  summary: ListChallengesResponseChallengeSummary;
  challengeTransition: V1ChallengeState;
  isPrivate: boolean;
  t(key: string): string;
}

const getBadgeProps = (
  participantState: ParticipantState,
  isPrivate: boolean,
): { tKey: string; badge: BADGE_PRIORITY } => {
  if (userTypeHandlers.isJoinedAlready(participantState)) {
    return {
      tKey: 'challenge.participant.joined',
      badge: BADGE_PRIORITY.light,
    };
  }

  if (participantState === ParticipantState.JOIN_REQUESTED) {
    return {
      tKey: 'challenge.participant.join-requested',
      badge: BADGE_PRIORITY.default,
    };
  }

  if (isPrivate && userTypeHandlers.isInPaymentState(participantState)) {
    return {
      tKey: 'challenge.participant.join-approved',
      badge: BADGE_PRIORITY.light,
    };
  }

  if (ParticipantState.JOIN_REJECTED === participantState) {
    return {
      tKey: 'challenge.participant.join-rejected',
      badge: BADGE_PRIORITY.default,
    };
  }

  return null;
};

export const _Badge: React.FC<IBadgeProps> = (props) => {
  const participantState = get(
    props.summary,
    'participation.state',
  ) as ParticipantState;

  if (props.challengeTransition === V1ChallengeState.FINISHED) {
    return null;
  }

  const badgeProps = participantState
    ? getBadgeProps(participantState, props.isPrivate)
    : null;

  if (!badgeProps) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Badge priority={badgeProps.badge}>
        <span className={styles.text}>{props.t(badgeProps.tKey)}</span>
      </Badge>
    </div>
  );
};

export const ChallengeBadge: React.FC<Omit<IBadgeProps, 't'>> = translate()(
  _Badge,
);
