import {
  ParticipantState,
  V1Challenge,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';
import * as React from 'react';
import { isChallengeAlreadyStarted } from '../../../../selectors/challenges';
import styles from './ChallengeCard.st.css';

interface ChallengeSubtitleProps {
  displayParams: {
    duration: boolean;
    image: boolean;
    divider: boolean;
    price: boolean;
    participants: boolean;
  };
  showParticipants: boolean;
  durationString: string;

  t(key: string, params?: {}): string;

  challenge: V1Challenge;
  participantsState: ParticipantState;
}

export const ChallengeCardSubtitle: React.FC<ChallengeSubtitleProps> = ({
  displayParams,
  showParticipants,
  durationString,
  t,
  challenge,
  participantsState,
}) => {
  if (challenge.transitions[0].state === V1ChallengeState.FINISHED) {
    return (
      <p className={styles.subtitle} data-hook="card-subtitle">
        <span className={styles.subtitleItem} data-hook="challenge-duration">
          {t('challenge.finished')}
        </span>
      </p>
    );
  }

  if (isChallengeAlreadyStarted(challenge, participantsState)) {
    return (
      <p className={styles.subtitle} data-hook="card-subtitle">
        <span className={styles.subtitleItem} data-hook="challenge-duration">
          {t('challenge.already-started')}
        </span>
      </p>
    );
  }

  return (
    (displayParams.duration || showParticipants) && (
      <p className={styles.subtitle} data-hook="card-subtitle">
        {!!displayParams.duration && (
          <span className={styles.subtitleItem} data-hook="challenge-duration">
            {durationString}
          </span>
        )}
        {displayParams.duration && showParticipants && (
          <span className={styles.subtitleItem} data-hook="challenge-bullet">
            {<>&nbsp;&bull;&nbsp;</>}
          </span>
        )}
        {!!showParticipants && (
          <span
            className={styles.subtitleItem}
            data-hook="challenge-participants"
          >
            {t(
              `challenge-card.${
                challenge.participantsSummary.participantsNumber > 1
                  ? 'participants_plural'
                  : 'participants'
              }`,
              {
                count: challenge.participantsSummary.participantsNumber,
              },
            )}
          </span>
        )}
      </p>
    )
  );
};
