import * as React from 'react';
import { translate, ITranslationProps } from 'react-i18next';

import styles from './EmptyList.st.css';

export interface IEmptyListProps {}

const EmptyListInner: React.FC<IEmptyListProps & ITranslationProps> = props => {
  const { t } = props;

  return (
    <section className={styles.root}>
      <div className={styles.text}>{t('challenge.empty-list')}</div>
    </section>
  );
};

export const EmptyList = translate()(EmptyListInner);
