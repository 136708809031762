import React from 'react';
import { SettingsConsumer } from './SettingsContext';

export function withSettings<Props, Settings>(Component: any) {
  return props => {
    return (
      <SettingsConsumer>
        {({ settings }) => {
          return (
            <Component {...(props as Props)} settings={settings as Settings} />
          );
        }}
      </SettingsConsumer>
    );
  };
}
