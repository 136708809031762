import {
  ParticipantState,
  V1AccessType,
  V1Challenge,
} from '@wix/ambassador-challenge-service-web/types';
import { IUserType } from '../contexts/User/UserContext';
import { userTypeHandlers } from '../contexts/User/helpers/userTypeHandlers';
import isAfter from 'date-fns/isAfter';
import endOfToday from 'date-fns/endOfToday';
import endOfDay from 'date-fns/endOfDay';

export function isPrivateChallenge(challenge: V1Challenge) {
  return (
    challenge?.settings?.accessRestrictions?.accessType === V1AccessType.PRIVATE
  );
}

export const isFlexible = (challenge: V1Challenge): boolean =>
  Boolean(challenge?.settings?.timelineType?.flexible?.duration);
export const isSpecific = (challenge: V1Challenge): boolean =>
  Boolean(challenge?.settings?.timelineType?.specific?.duration);

export const isChallengePaid = (challenge: V1Challenge): boolean => {
  return (
    Boolean(challenge?.settings?.pricing?.singlePayment?.price?.amount) ||
    Boolean(challenge?.settings?.pricing?.paidPlans?.length)
  );
};

export const isAvailableForJoinImmediately = (
  challenge: V1Challenge,
): boolean => !isPrivateChallenge(challenge);

export const isNeedToSelectStartDate = (
  challenge: V1Challenge,
  userType: IUserType,
) => {
  if (!isFlexible(challenge)) {
    return false;
  }

  if (userType === ParticipantState.INVITED) {
    return true;
  }

  if (
    isPrivateChallenge(challenge) &&
    isChallengePaid(challenge) &&
    !userTypeHandlers.isInPaymentState(userType)
  ) {
    return false;
  }

  if (userType === ParticipantState.JOIN_REQUESTED) {
    return false;
  }

  return true;
};

export const isPricingPickingForbidden = (
  challenge: V1Challenge,
  userType: IUserType,
): boolean => {
  return (
    isPrivateChallenge(challenge) &&
    isChallengePaid(challenge) &&
    !userTypeHandlers.isInPaymentState(userType)
  );
};

export function isChallengeAlreadyStarted(
  challenge: V1Challenge,
  participantState?: ParticipantState,
): boolean {
  const startTime = isSpecific(challenge)
    ? challenge?.settings?.timelineType?.specific?.startTime
    : false;

  if (!startTime || userTypeHandlers.isJoinedAlready(participantState)) {
    return false;
  }

  return isAfter(
    endOfToday(),
    endOfDay(new Date(startTime.year, startTime.month - 1, startTime.day)),
  );
}
