import React from 'react';
import { ChallengesListDataConsumer } from './ChallengesListContext';

export function withChallengesListData<IProps>(Component: any) {
  return props => {
    return (
      <ChallengesListDataConsumer>
        {value => {
          return <Component {...(props as IProps)} {...value} />;
        }}
      </ChallengesListDataConsumer>
    );
  };
}
