import * as React from 'react';
import { NewCard } from 'wix-ui-tpa/NewCard';
import {
  Button,
  PRIORITY as ButtonPriority,
  SIZE as ButtonSize,
} from 'wix-ui-tpa/Button';

import styles from './ChallengeCard.st.css';
import { ImageShape } from '../../Settings/challengeSettings/challengeSettings.types';
import { translate } from 'react-i18next';
import { imageDataToMediaUrl } from '@wix/challenges-web-library/dist/src/helpers/image';
import { getChallengePaidPlans } from '@wix/challenges-web-library/dist/src/formatters/serverClient/price';
import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library/dist/src/formatters/serverClient/timelineType';
import get from 'lodash/get';
import {
  ChallengePricing,
  MemberChallenge,
} from '@wix/ambassador-challenge-service-web/types';
import { ISettingsValues } from '../interfaces';
import { ChallengeBadge } from '../../Badge';
import { getCountPrefix } from '../../../../selectors/langCount';
import { withExperiments } from '../../../../contexts/Experiments/ExperimentsContext';
import { IExperimentsProviderProps } from '../../../../contexts/Experiments/interfaces';
import { serverToClientPricingNewInterface } from '@wix/challenges-web-library/dist/src';
import { getPaidPlanName } from '../../../ChallengesPage/Widget/components/Pricing/subscriptionTitle/getPaidPlanName';
import { isPrivateChallenge } from '../../../../selectors/challenges';
import { ChallengeCardSubtitle } from './ChallengeCardSubtitle';

interface IChallengeCard extends NewCard {
  memberChallenge: MemberChallenge;
  t(key: string, params?: {}): string;
  isMobile: boolean;
  buttonState: 'default' | 'hover';
  userPaidPlans: any;
  lng: string;
  settings: ISettingsValues;
  goToPage(params: any): void;
  renderingEnv: string;
  isFullWidth: boolean;
}

interface IChallengeCardState {
  cardWidth: number;
}

class ChallengeCardComponent extends React.Component<
  IChallengeCard & IExperimentsProviderProps,
  IChallengeCardState
> {
  static displayName = 'ChallengeCard';
  private readonly cardRef = React.createRef<HTMLDivElement>();

  state = {
    cardWidth: 0,
  };

  componentDidMount() {
    this.setState({ cardWidth: this.cardRef.current.offsetWidth || 0 });
  }

  componentDidUpdate(_prevProps, prevState: IChallengeCardState) {
    if (this.cardRef.current.offsetWidth !== prevState.cardWidth) {
      this.setState({ cardWidth: this.cardRef.current.offsetWidth || 0 });
    }
  }

  render() {
    const {
      memberChallenge,
      userPaidPlans,
      goToPage,
      lng,
      isMobile,
      buttonState,
      settings,
      renderingEnv,
      t,
      isFullWidth,
      ...rest
    } = this.props;

    const { challenge, summary } = memberChallenge;

    const displayParams = {
      image: settings.displayImage,
      duration: settings.displayDuration,
      participants: settings.displayParticipants,
      divider: settings.displayDivider,
      price: settings.displayPrice,
    };

    const {
      textAlignment,
      imageShape = ImageShape.Rectangle,
      imageRatio,
      cropSelection,
      buttonText,
    } = settings;

    const { cardWidth } = this.state;

    const langPrefix = getCountPrefix(
      get(challenge.settings.timelineType, 'flexible.duration.value', 0),
      lng,
    );

    const durationString = serverTimelineTypeToClientTimelineString(
      challenge.settings.timelineType as any,
      lng,
      t,
      'challenge-card.duration-string.ongoing',
      `challenge-card.duration-string.flexible.days${langPrefix}`,
      `challenge-card.duration-string.flexible.weeks${langPrefix}`,
    );

    const challengePaidPlans = getChallengePaidPlans(
      challenge.id,
      userPaidPlans,
    );
    const pricingString = serverToClientPricingNewInterface(
      (challenge.settings.pricing as any) as ChallengePricing,
      getPaidPlanName(challengePaidPlans, t),
      t,
      'challenge-card.pricing.free',
      'challenge-card.pricing.paid.separator',
    );

    const showParticipants = !!(
      displayParams.participants &&
      challenge.participantsSummary.participantsNumber
    );

    return (
      <NewCard
        {...styles(
          styles.card,
          {
            textAlignment,
            imageShape,
            cropSelection,
            mobile: isMobile,
            buttonState,
            imageRatio,
          },
          rest,
        )}
        stacked={true}
      >
        {displayParams.image && (
          <NewCard.Container className={styles.media}>
            <div className={styles.ratioBox}>
              <div
                data-hook="image-wrapper"
                className={styles.imageWrapper}
                style={{
                  ...(challenge.settings.description.media && {
                    backgroundImage: `url(${imageDataToMediaUrl({
                      ...(challenge.settings.description.media.image as any),
                      width: 800,
                      height: 600,
                      quality: 100,
                    })})`,
                  }),
                }}
              />
            </div>
          </NewCard.Container>
        )}
        <NewCard.Container className={styles.info}>
          <ChallengeBadge
            isPrivate={isPrivateChallenge(challenge)}
            summary={summary}
            challengeTransition={
              challenge.transitions['0'] && challenge.transitions['0'].state
            }
          />
          <div
            data-hook="info-wrapper"
            ref={this.cardRef}
            style={{ position: 'absolute', width: '100%' }}
          />
          <div className={styles.infoWrapper}>
            <h2 className={styles.title} data-hook="card-title">
              {challenge.settings.description.title}
            </h2>
            <ChallengeCardSubtitle
              participantsState={summary?.participation?.state}
              displayParams={displayParams}
              showParticipants={showParticipants}
              durationString={durationString}
              t={t}
              challenge={challenge}
            />
            {displayParams.divider && (
              <div data-hook="challenge-divider" className={styles.divider} />
            )}
            {displayParams.price && (
              <p data-hook="challenge-pricing" className={styles.pricing}>
                {pricingString}
              </p>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              data-hook="view-button"
              fullWidth={cardWidth > 300 || isFullWidth ? false : true}
              className={styles.button}
              priority={ButtonPriority.primary}
              size={ButtonSize.small}
              onClick={goToPage}
            >
              {t(
                buttonText === 'challenge-card.view-button'
                  ? 'challenge-card.view-button'
                  : buttonText,
              )}
            </Button>
          </div>
        </NewCard.Container>
      </NewCard>
    );
  }
}

export const ChallengeCard = translate()(
  withExperiments(ChallengeCardComponent),
);
