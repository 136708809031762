import {
  IUserPaidPlan,
  TranslateFunction,
} from '@wix/challenges-web-library/dist/src';

export function getPaidPlanName(
  paidPlans: IUserPaidPlan[],
  t: TranslateFunction,
) {
  if (!paidPlans.length) {
    return '';
  }
  if (paidPlans.length === 1) {
    return paidPlans[0].details.name;
  }
  return t('pricing.payment-option.subscription', {
    count: paidPlans.length,
  });
}
