import { ISettingsParam, ISettingsParams } from '@wix/tpa-settings';

export const enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const enum ImageShape {
  Square = 'square',
  Round = 'round',
  Rectangle = 'rectangle',
}

export const enum ImageRatio {
  HD = 'hd',
  Standard = 'standard',
}
export const enum CropSelection {
  TopLeft = 'TOP_LEFT',
  top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

export type IChallengeSettings = ISettingsParams<{
  itemsPerRow: ISettingsParam<number>;
  displayImage: ISettingsParam<boolean>;
  displayDuration: ISettingsParam<boolean>;
  displayParticipants: ISettingsParam<boolean>;
  displayDivider: ISettingsParam<boolean>;
  displayPrice: ISettingsParam<boolean>;
  textAlignment: ISettingsParam<TextAlignment>;
  cardSpacing: ISettingsParam<number>;
  imageShape: ISettingsParam<ImageShape>;
  imageRatio: ISettingsParam<ImageRatio>;
  cropSelection: ISettingsParam<CropSelection>;
  buttonText: ISettingsParam<string>;
}>;
